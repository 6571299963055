// Basics
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// Utilities
import { get } from 'lodash';

// View
import Layout from '../components/Organisms/Layout';

// Libraries
import { PageTemplate } from '../libraries/Parser';

class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const { wordpressPage: page, allWordpressAcfOptions: optionsPage } = data
    // console.log('page', page);
    return (
      <Layout
        link={page.link}
        title={page.title}
        isHomePage={page.wordpress_id === 194}
        isContactPage={page.wordpress_id === 475}
        seo={get(page, 'yoast_meta')}
      >
        <PageTemplate
          key="template-blog"
          wordPressId={page.wordpress_id}
          title={page.title}
          content={page.content}
          acf={page.acf}
          optionsPage={optionsPage}
        />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string,
    }),
  }),
}

export default IndexPage;

export const pageQuery = graphql`
query {
  allWordpressAcfOptions {
    edges {
      node {
        options {
          global_templates_work_industry_configurations {
            industries_list {
              industry_name
              icon_configuration {
                icon_color
                icon_name
                icon_type
              }
            }
          }
          global_templates_office_teams {
            teams {
              team_title
              team_description
              team_name
              team_unique_key
              team_members {
                member_introduction
                member_name
                member_position
                member_profile_picture {
                  localFile {
                    childImageSharp {
                      fluid (quality: 100) {
                        srcSetWebp
                      }
                    }
                  }
                }
                member_social_links {
                  platform
                  url
                }
              }
            }
          }

          global_templates_website_configuration_contact_settings {
            basic_details {
              company_name
            }
            contact_emails
            contact_forms {
              basic_form {
                section_content
                section_heading
                address_section {
                  section_heading
                }
                email_section {
                  section_heading
                  unique_key
                }
              }
            }
            emails {
              list {
                email
                name
                unique_key
              }
            }
            office_location {
              address_latitude
              address_longitude
              complete_address
              address_parts {
                address_line
              }
            }
          }
           
          global_templates_technology_configurations {
            technology_groups {
              industry_name
              technologies_list {
                technology_name
                icon_configuration {
                  icon_color
                  icon_name
                  icon_type
                  icon_image {
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  wordpressPage(wordpress_id: { eq: 194}) {
    id
    link
    title
    content
    wordpress_id
    yoast_meta {
      yoast_wpseo_title
      yoast_wpseo_metadesc
      yoast_wpseo_canonical
      yoast_wpseo_facebook_title
      yoast_wpseo_facebook_description
      yoast_wpseo_facebook_type
      yoast_wpseo_facebook_image
      yoast_wpseo_twitter_title
      yoast_wpseo_twitter_description
      yoast_wpseo_twitter_image
      yoast_wpseo_social_url
      yoast_wpseo_company_or_person
      yoast_wpseo_person_name
      yoast_wpseo_company_name
      yoast_wpseo_company_logo {
        source_url
      }
      yoast_wpseo_website_name
      yoast_wpseo_social_defaults {
        facebook_site
        fbadminapp
        instagram_url
        linkedin_url
        myspace_url
        og_default_image {
          source_url
        }
        og_frontpage_desc
        og_frontpage_image
        og_frontpage_title
        opengraph
        pinterest_url
        twitter
        twitter_card_type
        twitter_site
        wikipedia_url
        youtube_url
        pinterestverify
      }
    }
    acf {
      all_available_sections_page {
        ... on WordPressAcf_work_industries_builder {
          id
          internal {
            type
          }
          element_configuration {
            section_classes
            section_content
            section_heading
          }
        }
        ... on WordPressAcf_tile_content_builder {
          id
          internal {
            type
          }
          element_configuration {
            section_classes
            section_content
            section_heading
            section_tiles {
              section_heading
              icon_configuration {
                icon_color
                icon_name
                icon_type
              }
            }
          }
        }
        ... on WordPressAcf_technologies_builder {
          id
          internal {
            type
          }
          element_configuration {
            section_classes
            section_content
            section_heading
          }
        }
        ... on WordPressAcf_tab_content_builder {
          id
          internal {
            type
          }
          element_configuration {
            section_classes
            section_content
            section_heading
            section_tabs {
              section_heading
              content_list {
                section_heading
                icon_configuration {
                  icon_color
                  icon_name
                  icon_type
                  icon_image {
                    source_url
                  }
                }
              }
            }
          }
        }
        ... on WordPressAcf_header_builder {
          id
          internal {
            type
          }
          element_configuration {
            section_classes
            section_content
            section_heading
            section_image {
              localFile {
                childImageSharp {
                  fluid (quality: 100) {
                    srcSetWebp
                  }
                }
              }
            }
          }
        }
        ... on WordPressAcf_list_content_builder {
          id
          internal {
            type
          }
          element_configuration {
            section_classes
            section_content
            section_heading
            section_rows {
              section_content
              section_heading
            }
          }
        }

        ... on WordPressAcf_technologies_card_builder {
          id
          internal {
            type
          }
          element_configuration {
            section_classes
            section_content
            section_heading
          }
        }
        ... on WordPressAcf_gallery_builder {
          id
          internal {
            type
          }
          element_configuration {
            section_classes
            section_content
            section_heading
            section_images {
              section_image {
                localFile {
                  childImageSharp {
                    fluid {
                      srcWebp
                    }
                  }
                }
              }
            }
          }
        }
        ... on WordPressAcf_team_builder {
          id
          element_configuration {
            section_classes
            team_unique_key
          }
          internal {
            type
          }
        }
        ... on WordPressAcf_list_centralized_content_builder {
          id
          internal {
            type
          }
          element_configuration {
            section_classes
            section_rows {
              section_content
              section_heading
            }
          }
        }
        ... on WordPressAcf_contact_section_builder {
          id
          internal {
            type
          }
          element_configuration {
            section_classes
            section_content
            section_heading
            section_type
          }
        }
        ... on WordPressAcf_jobs_builder {
          id
          internal {
            type
          }
          element_configuration {
            job_type
            number_of_jobs
            section_classes
          }
        }
        ... on WordPressAcf_grid_content_builder {
          id
          internal {
            type
          }
          element_configuration {
            section_classes
            section_content
            section_heading
            section_grids {
              icon_configuration {
                icon_color
                icon_image
                icon_name
                icon_type
              }
              section_content
              section_heading
            }
          }
        }
      }
    }
  }
}
`
